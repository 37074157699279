.card{
    position: relative;
    
    overflow: hidden;
}

.card img {
    
    display: block;
    margin: auto;
    
    
}

#btn{

    color: white;
    background-color: black;
    font-size: large;
    border-radius: 1em;
    padding: .5em;
    margin: .5em;
}

#btn-hover :hover {
    background-color: gray;
}


.card .front {
    transform: rotateY(90deg);
    transition: all ease-in 0.2s;
    position:absolute;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    
}

.flipped .front{
    transform: rotateY(0deg);
    transition-delay: 0.2s;

}



.card .back {
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;
    width: 100%;
}

.flipped .back{
    transform: rotateY(90deg);
    
    transition-delay: 0s;
}

.card-grid {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    width: 50%;
    align-items: center;
    margin: auto;
}