.btn-area {
    background-color: white;
    position: relative;
    size: large;
    vertical-align: center;
}



.this-btn {
    margin: 0;
    position: fixed;
    top: 30%;
    
    transform: translate(-50%);
    outline-offset: 2em;
    outline-color: black;
    
    background-color: black;
    color: white;
    font-size: 8em;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 2em;
    margin: auto;
    
    padding: .5em;
    width: 50%;
}

#hover-btn :hover {
    background-color: gray;
}