#NavNav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    padding-left: 1em;
    padding-right: 1em;
    background-color: black;
    color:  white;
    justify-content: space-between;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    align-items: center;
    z-index: 100;
    height: 5em;
    
}
#expand {
    height: 6em;
}
#links {

    display: flex;
    font-size: large;
}
a {
    margin-left: 1em;
    margin-right: 1em;
    color: aqua;
}