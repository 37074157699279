#bio{
    display: flex;
    justify-content: center;
    border-style: dashed;
    max-width: 75%;
    align-items: center;
    margin: auto;

}

.biopic {
    outline-width: thick;
    outline-style: solid;
    outline-color: black;
    border-radius: 50%;
    
    width: 100%;
    height: auto;
    margin: 1em;
    
    
    
}

.biopic img {
    outline-width: thick;
    outline-style: solid;
    outline-color: black;
    object-fit: contain;
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.points{
    text-align: left;
}
