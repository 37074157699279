#gallery{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
}

.imageGallery {
    

    min-width: 20%;
    align-items: center;
    margin: auto;
    
}

img{
    align-items: center;
    max-height: 99%;
    max-width: 99%;
    border-radius: 1em;
}